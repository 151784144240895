// Bootstrap 4
// @import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/_mixins.scss";
@import "node_modules/bootstrap/scss/_root.scss";
@import "node_modules/bootstrap/scss/_reboot.scss";
@import "node_modules/bootstrap/scss/_type.scss";
@import "node_modules/bootstrap/scss/_images.scss";
// @import "node_modules/bootstrap/scss/_code.scss";
@import "node_modules/bootstrap/scss/_grid.scss";
@import "node_modules/bootstrap/scss/_tables.scss";
@import "node_modules/bootstrap/scss/_forms.scss";
@import "node_modules/bootstrap/scss/_buttons.scss";
@import "node_modules/bootstrap/scss/_transitions.scss";
@import "node_modules/bootstrap/scss/_utilities";
// @import "print";

* {
	font-family: 'Raleway';
}

body {
	height: 100vh;
}

.img-404 {
	background: url('../images/404_bg.jpg');
	background-position: center;
	background-size: cover;
	position:relative;
}

.opacity-filter{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
}

.filter-dark{
	background: rgba(0, 0, 0, 0.5);
}

.filter-light{
	background: rgba(255, 255, 255, 0.5);
}

.logo_404{
	z-index: 20;
	margin: auto;
}

h1 {
	font-size: 60px;
	font-weight: 700;
	padding-bottom: 10px;
	margin-bottom: 30px;
	position: relative;
}

h3 {
	color: #606f7b;
}

h1:after {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 4px;
	width: 64px;
	background: #f17e2d;
	content: '';
}

a.btn {
	font-weight: 700;
}
